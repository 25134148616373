<template>
	<v-row class="px-4">
		<v-col md="3" class="my-auto py-0" v-if="false">
			<label for="display-name" class="btx-label mt-2 required">Address Type </label>
		</v-col>
		<v-col md="9" class="py-0" v-if="false">
			<v-radio-group v-model="address.address_type" row hide-details class="ma-0">
				<v-radio label="Billing Address" :value="1" class="mr-3" color="blue darken-4"></v-radio>
				<v-radio label="Shipping Address" :value="2" class="ma-0" color="blue darken-4"></v-radio>
			</v-radio-group>
		</v-col>
		<v-col md="3" class="my-auto py-0">
			<label for="address-label" class="btx-label mt-2 required">Label </label>
		</v-col>
		<v-col md="9" class="py-0">
			<TextInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="address-label"
				placeholder="Label"
				v-model="address.label"
				:rules="[vrules.required(address.label, 'Label')]"
				:class="{ required: !address.label }"
			></TextInput>
		</v-col>
		<v-col md="3" class="my-auto py-0">
			<label for="display-name" class="btx-label mt-2 required"
				>Display Name
				<TooltipQuestion>
					<template v-slot:text
						>This name will be displayed on<br />the transactions you create for<br />this
						address</template
					>
				</TooltipQuestion>
			</label>
		</v-col>
		<v-col md="9" class="py-0">
			<TextValidateInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				placeholder="Display Name"
				id="display-name"
				:rules="[
					vrules.required(address.display_name, 'Display Name'),
					vrules.minLength(address.display_name, 'display name', 2),
					vrules.maxLength(address.display_name, 'display name', 100),
				]"
				:class="{
					required: !address.display_name,
				}"
				:validationField="{
					url_type: 'customer',
					filter_type: 'address',
					field: 'display_name',
				}"
				:parent-id="!newAddress ? customerId : null"
				:current-id="address.id"
				show-dropdown
				v-model="address.display_name"
			></TextValidateInput>
			<TextInput
				v-if="false"
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="display-name"
				:rules="[vrules.required(address.display_name, 'Display Name')]"
				:class="{
					required: !address.display_name,
				}"
				placeholder="Display Name"
				v-model="address.display_name"
			></TextInput>
		</v-col>
		<v-col md="3" class="my-auto py-0">
			<label for="address-phone-no" class="btx-label mt-2 required">Phone</label>
		</v-col>
		<v-col md="9" class="py-0">
			<PhoneTemplate
				v-if="false"
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="address-phone-no"
				placeholder="Phone No."
				v-model="address.phone_no"
				:rules="[
					vrules.required(address.phone_no, 'Phone No'),
					vrules.phoneNumber(address.phone_no, 'Phone No', 12),
				]"
				:class="{ required: !address.phone_no }"
			></PhoneTemplate>
			<PhoneInput
				required
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="address-phone-no"
				placeholder="Phone No."
				v-model="address.phone_no"
				:validationField="{
					url_type: 'customer',
					filter_type: 'address',
					field: 'phone_number',
				}"
				:parent-id="!newAddress ? customerId : null"
				:current-id="address.id"
			></PhoneInput>
		</v-col>
		<v-col md="3" class="my-auto py-0">
			<label for="address-email" class="btx-label mt-2 required">Email Address</label>
		</v-col>
		<v-col md="9" class="py-0">
			<EmailInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="address-email"
				placeholder="Email Address"
				v-model="address.email"
				:rules="[vrules.required(address.email, 'Email Address')]"
				:class="{ required: !address.email }"
				:validationField="{
					url_type: 'customer',
					filter_type: 'address',
					field: 'email',
				}"
				:parent-id="!newAddress ? customerId : null"
				:current-id="address.id"
			></EmailInput>
		</v-col>
		<v-col md="3" class="my-auto py-0">
			<label for="postal-code" class="btx-label mt-2 text-right required">Postal Code </label>
		</v-col>
		<v-col md="9" class="py-0">
			<v-text-field
				outlined
				class="mt-3"
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="postal-code"
				placeholder="Postal Code"
				type="number"
				v-mask="'######'"
				v-model="address.address_postal_code"
				:rules="[
					vrules.required(address.address_postal_code, 'address postal code'),
					vrules.phoneNumber(address.address_postal_code, 'address postal code', 6),
				]"
				:class="{
					required: !address.address_postal_code,
				}"
			></v-text-field>
		</v-col>
		<v-col md="3" class="my-auto py-0">
			<label for="address-line-1" class="btx-label mt-2 required">Address Line 1 </label>
		</v-col>
		<v-col md="9" class="py-0">
			<TextInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				:rules="[vrules.required(address.address_line_1, 'Address line 1')]"
				:class="{ required: !address.address_line_1 }"
				id="address-line-1"
				placeholder="Address Line 1"
				v-model="address.address_line_1"
			></TextInput>
		</v-col>
		<v-col md="3" class="my-auto py-0">
			<label for="address-line-2" class="btx-label mt-2">Address Line 2 </label>
		</v-col>
		<v-col md="9" class="py-0">
			<TextInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="address-line-2"
				placeholder="Address Line 2"
				v-model="address.address_line_2"
			></TextInput>
		</v-col>
		<v-col md="3" class="my-auto py-0">
			<label for="unit-number" class="btx-label mt-2">Unit No. </label>
		</v-col>
		<v-col md="9" class="py-0">
			<TextInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="unit-number"
				placeholder="Unit No."
				v-model="address.unit_number"
			></TextInput>
		</v-col>
		<v-col md="3" class="my-auto py-0">
			<label for="address-country" class="btx-label mt-2">Country</label>
		</v-col>
		<!-- <pre>
			{{ address }}
		</pre> -->
		<v-col md="9" class="py-0">
			<TextInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="address-country"
				placeholder="Country"
				v-model="address.address_country"
			></TextInput>
		</v-col>
		<AddressTemplate v-if="false" v-model="address"></AddressTemplate>
		<v-col md="3" class="mt-2 py-0" v-if="false">
			<label :for="`address-street-1-${stringId}`" class="btx-label mt-2 required">Address</label>
		</v-col>
		<v-col md="9" class="py-0" v-if="false">
			<TextAreaInput
				hide-details
				:id="`address-street-1-${stringId}`"
				placeholder="Address"
				v-model="address.address_line_1"
				:disabled="pageLoading"
				:rules="[vrules.required(address.address_line_1, 'Address')]"
				:loading="pageLoading"
				:class="{
					required: !address.address_line_1,
				}"
			></TextAreaInput>
		</v-col>
	</v-row>
</template>
<script>
import { mapGetters } from "vuex";
import TextInput from "@/view/components/TextInput";
import AddressTemplate from "@/view/components/AddressTemplate";
import TextAreaInput from "@/view/components/TextAreaInput";
import EmailInput from "@/view/components/EmailInput";
import PhoneTemplate from "@/view/components/Phone";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import ApiService from "@/core/services/api.service";
import { isObject, isEqual } from "lodash";
import PhoneInput from "./PhoneInput.vue";
import TextValidateInput from "./TextValidateInput.vue";

export default {
	name: "btx-address",
	title: "Address",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [Object, String],
			default: null,
		},
		isLocation: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		type: {
			type: Number,
			default: 1,
		},
		uuid: {
			type: String,
			default: null,
		},
		customerId: {
			type: [Number, String],
			default: null,
		},
		newAddress: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			countries: [],
			states: [],
			cities: [],
			companyNameError: null,
			contactPersons: [],
			locationList: [],
			countryLoading: false,
			contactPersonDialog: false,
			stateLoading: false,
			cityLoading: false,
			pageLoading: false,
			address: {
				id: null,
				uuid: null,
				address_type: 1,
				name: null,
				display_name: null,
				company_name: null,
				phone_no: null,
				email: null,
				location: null,
				address_line_1: null,
				address_line_2: null,
				address_country: "Singapore",
				address_postal_code: null,
				unit_number: null,
				label: null,
			},
		};
	},
	watch: {
		address: {
			deep: true,
			handler(param) {
				this.$emit("input", param);
			},
		},
		value: {
			deep: true,
			handler() {
				this.updateAddress();
			},
		},
	},
	methods: {
		getContactPersonList() {
			this.$emit("updateContactPersonList", true);
		},
		updateAddress() {
			if (isObject(this.value) && !isEqual(this.value, this.address)) {
				this.address = {
					id: this.value.id || null,
					uuid: this.value.uuid || null,
					address_type: this.value.address_type || null,
					name: this.value.name || null,
					display_name: this.value.name || null,
					unit_number: this.value.unit_number || null,
					company_name: this.value.company_name || null,
					phone_no: this.value.phone_no || null,
					email: this.value.email || null,
					location: this.value.location || null,
					address_line_1: this.value.address_line_1 || null,
					address_line_2: this.value.address_line_2 || null,
					address_state: this.value.address_state || null,
					address_country: this.value.address_country || null,
					address_postal_code: this.value.address_postal_code || null,
					label: this.value.label || null,
				};
			}
			this.$nextTick(() => {
				if (this.address.address_country) {
					// this.getStates();
					// if (this.address.address_state) {
					// 	this.getCities();
					// }
				}
			});
		},
		getCountries() {
			const countries = localStorage.getItem("countries");
			if (countries) {
				this.countries = JSON.parse(countries);
			} else {
				this.countries = [
					{
						name: "Singapore",
						code: "SG",
						dial_code: "+65",
					},
				];
				localStorage.setItem("countries", JSON.stringify(this.countries));
			}
		},
		getStates() {
			const { address_country } = this.address;
			const key = `states-${address_country}`.toLowerCase();
			const state = localStorage.getItem(key);
			if (state) {
				this.states = JSON.parse(state);
			} else {
				if (this.stateLoading) {
					return false;
				}
				this.stateLoading = true;
				ApiService.post("https://countriesnow.space/api/v0.1/countries/states", {
					country: address_country,
				})
					.then(({ data }) => {
						const { states } = data;
						this.states = states;
						localStorage.setItem(key, JSON.stringify(states));
					})
					.catch((error) => {
						console.log({ error });
					})
					.finally(() => {
						this.stateLoading = false;
					});
			}
		},
		getCities() {
			const { address_country, address_state } = this.address;
			const key = `cities-${address_country}-${address_state}`.toLowerCase();
			const cities = localStorage.getItem(key);
			if (cities) {
				this.cities = JSON.parse(cities);
			} else {
				if (this.cityLoading) {
					return false;
				}
				this.cityLoading = true;
				ApiService.post("https://countriesnow.space/api/v0.1/countries/state/cities", {
					country: address_country,
					state: address_state,
				})
					.then(({ data }) => {
						this.cities = data;
						localStorage.setItem(key, JSON.stringify(data));
					})
					.catch((error) => {
						console.log({ error });
					})
					.finally(() => {
						this.cityLoading = false;
					});
			}
		},
	},
	components: {
		TextInput,
		EmailInput,
		PhoneTemplate,
		TooltipQuestion,
		TextAreaInput,
		AddressTemplate,
		PhoneInput,
		TextValidateInput,
	},
	computed: {
		...mapGetters(["localDB"]),
		stringId() {
			return this.stringUnique();
		},
	},
	mounted() {
		this.locationList = this.localDB("locations", []);
		this.updateAddress();
		this.getCountries();
		//this.address.address_country = "Singapore";
	},
};
</script>
